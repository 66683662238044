import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import ReactScrollableList from "react-scrollable-list";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./App.css";

function App() {
  const [socket, setSocket] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [thumbButtonEnabled, setThumbButtonEnabled] = useState(true);
  const [textureButtonEnabled, setTextureButtonEnabled] = useState(true);
  const [listMessages, setListMessages] = useState([]);
  useEffect(() => {
    const newSocket = io();
    setSocket(newSocket);
    console.log("created socket");
    const messageListener = (message) => {
      setListMessages((prevMessages) => [...prevMessages, message]);
    };

    newSocket.on("message", messageListener);
    return () => newSocket.close();
  }, [setSocket]);

  const startMaterials = () => {
    confirmAlert({
      title: "Confirm to initiate material sync",
      message:
        "This will override existing materials with the same name on the asset library. Any work done to these materials will be LOST. Do you want to proceed?",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            console.log("Starting Materials sync...");
            socket.emit("materials");
            setButtonEnabled(false);
          },
        },
        {
          label: "CANCEL",
        },
      ],
    });
  };
  const startThumbnails = () => {
    confirmAlert({
      title: "Confirm to initiate thumbnails sync",
      message:
        "This will override thumbnail entries on the datatable. It will update the appearance of the option swatches on the Frontend app. Do you want to proceed?",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            console.log("Starting Thumbnails sync...");
            socket.emit("thumbnails");
            setThumbButtonEnabled(false);
          },
        },
        {
          label: "CANCEL",
        },
      ],
    });
  };
  const startTextures = () => {
    confirmAlert({
      title: "Confirm to initiate texture sync",
      message:
        "This will update the textures of the same name in the asset library. It will override the basemap texture of any affected material. Do you want to proceed?",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            console.log("Starting Textures sync...");
            socket.emit("textures");
            setTextureButtonEnabled(false);
          },
        },
        {
          label: "CANCEL",
        },
      ],
    });
  };
  // const messages = new Array(50)
  //   .fill(1)
  //   .map((_, idx) => ({ id: idx, content: "hello" }));
  return (
    <div className="App">
      <h1>Teknion Materials App</h1>
        <div className="button-container">
          <p>Sync DAM Materials</p>
          <button onClick={startMaterials} disabled={!buttonEnabled}>
            {" "}
            Start{" "}
          </button>
        </div>
        <div className="button-container">
          <p>Sync DAM Textures</p>
          <button onClick={startTextures} disabled={!textureButtonEnabled}>
            {" "}
            Start{" "}
          </button>
        </div>
        <div className="button-container">
          <p>Sync DAM Thumbnails</p>
          <button onClick={startThumbnails} disabled={!thumbButtonEnabled}>
            {" "}
            Start{" "}
          </button>
        </div>
      <p>Progress:</p>
      <ReactScrollableList
        listItems={listMessages}
        heightOfItem={30}
        maxItemsToRender={50}
        style={{ color: "#333" }}
      />
    </div>
  );
}

export default App;
